import React, { Component } from 'react';
import { Button, Input, Form } from 'reactstrap';
import { Loader } from '../../../vibe';
import { postVerifiedStudentInfo, getMembersWithCertificates, patchMemberCertificate, addMemberToOrganization } from '../../../vibe/helpers/apiHelper';
import PendingMember from '../../elements/members/PendingMember';
import PaginationComp from '../../../vibe/components/utilities/Pagination/Pagination';
import * as Icon from 'react-feather';
import {patchMember} from '../../../vibe/helpers/apiHelper';
import { shortedPersonalIdentificationNumber } from '../../../vibe/helpers/util';
import {ArrowLeft} from 'react-feather';

const pageSize = 10

const CoffeeWithSteam = ({ size = 48, className = "" }) => (
<div className={`coffee-container ${className}`}>
    <div className="steam-container">
      {[1, 2, 3].map((i) => (
        <div key={i} className={`squiggle-container squiggle-container-${i}`}>
          <svg viewBox="0 0 28 80" width="10" height="25">
            <path d="M22 75C14 69.4 6.8 64.5 6.8 55.1c0-12.1 14.1-17.2 14.1-29.6C20.9 16.4 14.2 9.8 4.9 5.4" />
          </svg>
        </div>
      ))}
    </div>
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M2 13h16v4a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4v-4z" fill="#dfad8a" stroke="none" />
      <path d="M18 8h1a4 4 0 0 1 0 8h-1" />
      <path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z" />
    </svg>
</div>
);

class PendingMembers extends Component {
  constructor(props) {
 
    super(props);
    this.state = {
      searchFilter: '',
      submitSearch: false,
      totalCount: null,
      loading: true,
      membersLoading: true,
      selectedMembers: [],
      allSelected: false,
      members: [],
      bulkValidationErrors: {},
      showBulkConfirmation: false,
      showBulkRejectConfirmation: false,
    }

  }

  // Add method to handle member acceptance
  handleMemberAccepted = (memberId) => {
    // First set isRemoving flag to trigger fade animation
    this.setState(prevState => ({
      members: prevState.members.map(member => 
        member.id === memberId 
          ? { ...member, isRemoving: true }
          : member
      )
    }));

    // After animation duration, remove the member
    setTimeout(() => {
      this.setState(prevState => ({
        members: prevState.members.filter(member => member.id !== memberId),
        totalCount: prevState.totalCount - 1,
        // Also update selectedMembers if needed
        selectedMembers: prevState.selectedMembers.filter(id => id !== memberId)
      }));
    }, 500); // Match this with CSS transition duration
  }


  handleSingleSelect = (memberId, isSelected) => {
    this.setState(prevState => {
      const newSelectedMembers = isSelected 
        ? [...prevState.selectedMembers, memberId]
        : prevState.selectedMembers.filter(id => id !== memberId);
      
      return {
        selectedMembers: newSelectedMembers,
        allSelected: newSelectedMembers.length === prevState.members.length
      };
    });
  };

  validateBulkOperation = () => {
    const { members, selectedMembers } = this.state;
    const errors = {};
    let hasErrors = false;
    
    const selectedMembersData = members.filter(m => 
      selectedMembers.includes(m.id)
    );
  
    selectedMembersData.forEach(member => {
      errors[member.id] = {};
      
      if (!member.pace) {
        errors[member.id].pace = 'Study pace required';
        hasErrors = true;
      }
      if (!member.valid_from) {
        errors[member.id].valid_from = 'Start date required';
        hasErrors = true;
      }
      if (!member.valid_to) {
        errors[member.id].valid_to = 'End date required';
        hasErrors = true;
      }
      if (member.valid_from && member.valid_to) {
        if (new Date(member.valid_to) < new Date(member.valid_from)) {
          errors[member.id].valid_to = 'End date must be after start date';
          hasErrors = true;
        }
      }
    });
  
    this.setState({ bulkValidationErrors: errors });
    return !hasErrors;
  };


  handleBulkAccept = async () => {
    // First, validate the operation
    if (!this.validateBulkOperation()) {
      return;
    }

    this.setState({ showBulkConfirmation: true });
  }

  cancelBulkConfirmation = () => {
    this.setState({ showBulkConfirmation: false });
  }

  handleConfirmedBulkAccept = async () => {  
    const { members, selectedMembers } = this.state;
    const selectedMembersData = members.filter(m => 
      selectedMembers.includes(m.id)
    );
  
    // Process members sequentially
    for (const member of selectedMembersData) {
      try {
        // Remove the dates from member object as in single accept
        const { pace, valid_from, valid_to, member_certificate, ...memberWithouCerttDates } = member;
        const updatedMember = {
          ...memberWithouCerttDates,
          status: member.status === 'A' ? 'A' : 'N',
        };
  
        // Use the same pattern as single accept
        await new Promise((resolve, reject) => {
          patchMember(updatedMember, 
            () => {
              
              if(member.country === 'SE'){
                // First update member status
                var pinShort = shortedPersonalIdentificationNumber(member.personal_identification_number);
    
                let verificationData = {
                  personal_identification_number_short: pinShort,
                  study_pace: member.pace.toString(),
                  valid_from: new Date(member.valid_from),
                  valid_to: new Date(member.valid_to)
                };
    
                // Then post verification info
                postVerifiedStudentInfo(verificationData, 
                  () => {
                    this.handleMemberAccepted(member.id);
                    resolve();
                  },
                  (error) => {
                    console.error('Error posting verification:', error);
                    reject(error);
                  }
                );
              }

              if (member.member_certificate && member.member_certificate.length > 0) {
                  const lastCertificate = member.member_certificate[0];
                  // First add member_membership only SE
                  if(member.country === 'SE'){
                    addMemberToOrganization(
                      member.id,
                      lastCertificate.organization_id,
                      ()=>{
                        // Last Patch Certificate
                        patchMemberCertificate(
                          lastCertificate.id,
                          2, // Accepted status
                          () => {},
                          () => {},
                        );
                      }
                  )
                }else{
                  // Last Patch Certificate
                    patchMemberCertificate(
                      lastCertificate.id,
                      2, // Accepted status
                      () => {},
                      () => {},
                    );
                }
              
              }
            },
            (error) => {
              console.error('Error accepting member:', error);
              reject(error);
            }
          );
        });
  
      } catch (error) {
        console.error(`Failed to process member ${member.id}:`, error);
      }
    }
  
    // Clear selection after processing
    this.setState({ 
      selectedMembers: [], 
      allSelected: false,
      bulkValidationErrors: {},
      showBulkConfirmation: false 
    });
  };


  handleBulkReject = () => {
    // Show rejection confirmation UI
    this.setState({ showBulkRejectConfirmation: true });
  }
  
  
  handleConfirmedBulkReject = () => {
    // Can directly reject all selected members
    const selectedMembers = this.state.members.filter(m => 
      this.state.selectedMembers.includes(m.id)
    );
  
    selectedMembers.forEach(member => {

      const { pace, valid_from, valid_to, member_certificate, ...memberWithouCerttDates } = member;
      const updatedMember = {
        ...memberWithouCerttDates,
        status: member.status === 'A' ? 'A' : 'C',
      };
  
      patchMember(updatedMember, 
        () => {
          this.handleMemberAccepted(member.id);
        },
        (error) => {
          console.error('Error rejecting member:', error);
        }
      );
      if (member.member_certificate && member.member_certificate.length > 0) {
        const certificateId = member.member_certificate[0].id;
        patchMemberCertificate(
          certificateId,
          3, // Reject status
          () => {},
          () => {},
        );
      }

    });

    // Clear selection and hide confirmation
    this.setState({ 
      selectedMembers: [], 
      allSelected: false,
      showBulkRejectConfirmation: false 
    });
  }
  
  cancelBulkRejectConfirmation = () => {
    // Simply hide the rejection confirmation UI
    this.setState({ showBulkRejectConfirmation: false });
  }

  

  updateMemberData = (memberId, data) => {
    this.setState(prevState => ({
      members: prevState.members.map(member => 
        member.id === memberId 
          ? { ...member, ...data }
          : member
      )
    }));
  }
  
  componentDidMount() {    
    const search = this.state.searchFilter

    this.setState({membersLoading: true, totalCount: null, loading: true})
    getMembersWithCertificates(0, '', search, '', (result) => {
      console.log('Members ===>>>>', result.members)
      this.setState({ totalCount: result.totalCount, members: result.members, currentPage: 1, loading: false, membersLoading: false })
      
    }, (error) => {
      this.setState({ loading: false, totalCount: 0, currentPage: -1, membersLoading: false })
    })
}



  componentDidUpdate(prevProps, prevState) {
    if (prevState.submitSearch !== this.state.submitSearch || prevState.membership_id !== this.state.membership_id) {

      if (this.state.submitSearch) {
        const search = this.state.searchFilter
        this.setState({membersLoading: true, totalCount: null})

        getMembersWithCertificates(0, '', search, '', (result) => {
          this.setState({ totalCount: result.totalCount, members: result.members, currentPage: 1, loading: false, submitSearch: false, membersLoading: false })
        }, (error) => {
          this.setState({ totalCount: 0, members: [], currentPage: -1, loading: false, submitSearch: false, membersLoading: false })
        })
      }
    }
  }


  renderMembers() {
    if (this.state && this.state.members) {
      if (this.state.members.length === 0) {
        return (
          <>
          <div className="text-center my-5">
            <div className="mb-3">
              <CoffeeWithSteam size={48} className="text-muted" />
            </div>
            <h4 className="text-muted mb-3">Woohoo! All Caught Up! 🎉</h4>
            <p className="text-muted">
              Time to grab a coffee - you've verified all members! 
            </p>
          </div>
          </>
        );
      }

      return this.state.members.map((member) => {
        const validationErrors = this.state.bulkValidationErrors[member.id] || {};
        return (
          <PendingMember 
            key={member.id} 
            member={member}
            isSelected={this.state.selectedMembers.includes(member.id)}
            onSelectMember={this.handleSingleSelect}
            onMemberAccepted={this.handleMemberAccepted}
            onUpdateMember={this.updateMemberData}
            validationErrors={validationErrors}
          />
        );
      });
    }
  }

  onClickPagination(e, page) {
    this.setState({ currentPage: page, loading: true })
    var offset = (page - 1) * pageSize
    const search = this.state.searchFilter

    this.setState({membersLoading: true, totalCount: null})
    getMembersWithCertificates(offset, '', search, '', (result) => {
      this.setState({ totalCount: result.totalCount, members: result.members, loading: false, membersLoading: false })
      
    }, (error) => {
      this.setState({ loading: false, totalCount: 0, currentPage: -1, membersLoading: false })
      console.log(error)
    })
  }

  renderPagination() {
    return <PaginationComp currentPage={this.state.currentPage} lastPage={this.getLastPage()} onClickPagination={(e, page) => this.onClickPagination(e, page)} />
  }

  getLastPage() {
    return Math.ceil(this.state.totalCount / pageSize)
  }

  handleSearch = event => {
    this.setState({ searchFilter: event.target.value, submitSearch: false });
  }

  submitSearch = (e) => {
    e.preventDefault();
    this.setState({ submitSearch: true });
  }
 
  formatNumber(number) {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    return "0"
  }

  hasEmptyTotalCount() {
    return this.state.totalCount == null && this.state.totalCount !== 0 
  }

  render() {
    if (this.state) {
      if (this.state.loading) {
        return <Loader type="dots" />
      } else if (this.state.error) {
        return <div>{this.state.error.statusCode} {this.state.error.name} {this.state.error.message}</div>
      } else {
        return (
          <React.Fragment>
         
         <div className="row mb-3 justify-content-between">
          <div className="col-md-6">
            <h1 className="h5 mb-2">
              Member Verification 
              (
              {!this.state.membersLoading && 
              !this.hasEmptyTotalCount() && (
                <span>{this.formatNumber(this.state.totalCount)} Pending</span>
              )}
              )
            </h1>
          </div>

              <div className="col-md-4">
                <Form onSubmit={(e) => this.submitSearch(e)}>
                  <div className="d-flex align-items-center">
                    <Input
                      type="text"
                      autoComplete="off"
                      placeholder="Search..."
                      name="search"
                      id="search"
                      className="form-control mr-1 flex-grow-1"
                      onChange={this.handleSearch}
                      title="Search by member ID, PIN, first or last name"
                    />
                    <Button
                      type="submit"
                      onClick={(e) => this.submitSearch(e)}
                      color="primary"
                    >
                      {this.state.submitSearch ? (
                        <Icon.Loader className="rotating" size="20" color="#FFFFFF" />
                      ) : (
                        "Search"
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>


        
            {this.state.membersLoading ? <Loader type="dots" /> : <div>
              {this.renderMembers()}
              {this.state.totalCount > 10 && this.state.currentPage > 0 && 
                this.renderPagination()
              }
            </div>}
            

            {this.state.selectedMembers && this.state.selectedMembers.length > 0 && (
             <div className="fixed-bottom bg-white border-top shadow-sm p-3">
             <div className="container-fluid">
               <div className="d-flex justify-content-between align-items-center">
                 <div className="d-flex align-items-center">
                   <span className="font-weight-medium">
                     {this.state.selectedMembers.length} selected
                   </span>
                 </div>
                 <div>
                 {!this.state.showBulkConfirmation && !this.state.showBulkRejectConfirmation ? (
                    <>
                      <Button 
                        color="danger" 
                        className="mr-2"
                        onClick={this.handleBulkReject}
                      >
                        Reject Selected
                      </Button>
                      <Button 
                        color="success"
                        onClick={this.handleBulkAccept}
                      >
                        Accept Selected
                      </Button>
                    </>
                  ) : this.state.showBulkRejectConfirmation ? (
                    <>
                      <Button 
                        color="link" 
                        className="mr-2 text-muted"
                        onClick={this.cancelBulkRejectConfirmation}
                      >
                        <ArrowLeft size={14} className="mr-1" />
                        Back
                      </Button>
                      <Button 
                        color="danger" 
                        onClick={this.handleConfirmedBulkReject}
                      >
                        Confirm Rejection
                      </Button>
                    </>
                  ) : (
                    // Existing accept confirmation UI
                    <>
                      <Button 
                        color="link" 
                        className="mr-2 text-muted"
                        onClick={this.cancelBulkConfirmation}
                      >
                        <ArrowLeft size={14} className="mr-1" />
                        Back
                      </Button>
                      <Button 
                        color="success" 
                        onClick={this.handleConfirmedBulkAccept}
                      >
                        Confirm
                      </Button>
                    </>
                  )}
                 </div>
               </div>
             </div>
           </div>
            )}
          </React.Fragment>
        )
      }
    } else {
      return null
    }

  }
}

export default PendingMembers;
