import { Component } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label } from 'reactstrap';

class DropDownMultiSelect extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showDropDown: false
        }
    }
    
    componentDidMount() {
        
    }

    toggleDropDown = () => {
        this.setState({showDropDown: !this.state.showDropDown})
    }

    renderDropDown() {

        return <>
            <Dropdown className="mb-4 mt-2" isOpen={this.state.showDropDown} toggle={this.toggleDropDown}>
                <DropdownToggle color="primary" disabled={this.props.buttonDisabled}>
                    <i className="fa fa-plus-circle mr-1" />
                    {this.props.title ?? "Select organizations"}
                </DropdownToggle>
                <DropdownMenu>
                    {this.props.items?.map((item, index) => (
                    <DropdownItem key={index} toggle={false} onClick={(e) => this.props.onClickItem(e, item)}>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={this.props.itemIsSelected(item)}
                                    onChange={(e) => this.props.onClickItem(e, item)} />
                                    {item.label}
                                </Label>
                        </FormGroup>
                    </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </>
    }

    render() {
        return <>
            {this.renderDropDown()}
            </>
    }
}

export default DropDownMultiSelect;