import React from 'react';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import {addFormToTicket, getTicketsForms, deleteFormFromTicket, createEventOccurrenceTickets, createOrganizationEvent, createOrganizationEventOccurrence, createOrganizationEventTag, createTickets, deleteOrganizationEventOccurrence, deleteOrganizationEventTag, deleteTickets, getCurrentUser, getOrganizationEvent, getSegments, patchOrganizationEvent, updateOrganizationEventOccurrence, getAllForms, updateTicketsById, createOrganizationEventOccurrenceNotification, updateNotificationById, deleteNotificationById, getEventSegments, promisifyOrFalse, createOccurrenceOption, patchOccurrenceOption, createEventOccurrenceInterest, getInterestForms, deleteFormFromInterest, addFormToInterest, getInterests, createEventOrganization, deleteEventOrganization, deleteEventOccurrenceInterests } from '../../../../vibe/helpers/apiHelper';
import { getCampaigns, addCampaignToTicketPackage, deleteCampaignFromTicketPackage } from '../../../../api/campaign/campaign'
import { addBaseSegmentIfMissing, getTicketSegmentOptions, parseErrorMessage } from '../../../../vibe/helpers/util';
import MediaSelect from '../../../elements/media/MediaSelect';
import EditableForm from '../../common/EditableForm';
import Select from 'react-select'
import FileUpload from '../../../elements/media/upload'
import { postMediaDragDrop } from '../../../../vibe/helpers/apiHelper';
import OrganizationTags from './OrganizationTags';
import { formatDate } from '../../../../vibe/helpers/dateHelper';
import EventAttendees from './EventAttendees';
import { EVENT_TAG_IDS } from '../../../../vibe/helpers/organizationHelper';
import EditOccurrence from './occurrence/EditOccurrence';
import { eventCanBeDeleted, occurrenceCanBeDeleted, sortByDate } from '../../../../vibe/helpers/eventHelper';
import OccurrenceList from './occurrence/OccurrenceList';
import { getMediaImage } from '../../../../vibe/helpers/mediaHelper';
import OccurrenceNotifications from './occurrence/OccurrenceNotifications';
import { v4 as uuidv4 } from 'uuid';
import { clone, cloneAll } from '../../../../vibe/helpers/cloneUtil';
import { DELETE, EDIT, initAll, NEW, onAddObject, onEditObject, PUBLISH_STATUS_ARCHIVED, removeItem, setItem, UNCHANGED } from '../../../elements/form/FormAction';
import { Loader } from '../../../../vibe';
import MultiLanguageInput from '../../common/MultiLanguageInput';
import { toMultiLanguageData } from '../../../../vibe/helpers/language';
import MultiLanguageNoImageClassicEditor from '../../../elements/form/MultiLanguageNoImageClassicEditor';
import OrganizationEventCoHosts from './OrganizationEventCoHosts';
import SimpleHelpPopover from '../../../elements/common/SimpleHelpPopover';

const titleLanguageKeys = {
    primary: 'title', 
    secondary: 'title_en'
}

const contentLanguageKeys = {
    primary: 'content', 
    secondary: 'content_en'
}

class EditOrganizationEvent extends EditableForm {

    constructor(props) {
        super(props);
        this.state = {
            occurrence: null,
            clonedOccurrence: null,
            clonedTickets: [],
            clonedInterests: [],
            segmentOptions: [],
            interestOptions: [],
            eventSegmentOptions: [],
            ticketSegmentOptions: [],
            allInterests: [],
            eventDelButtonDisabled: false,
            formOptions: [],
            occurrencePushes: [],
            selectedTag: null,
            tags: [],
            attendeeModalOpen: false,
            tickets: [],
            organizationEventOccurrences: [],
            eventOrganizations: [] // co-hosts
        }
        this.toggleEventAttendeesModal = this.toggleEventAttendeesModal.bind(this)
    }
 
    getEvent(id) {
        if (id) {
            getOrganizationEvent(id, (organizationEvent) => {
                var organizationEventOccurrences = [...organizationEvent?.organization_event_occurrences ?? []]
                var tags = [...organizationEvent?.organization_event_tags ?? []]
                var eventOrganizations = [...organizationEvent?.event_organizations ?? []]

                organizationEvent._action = UNCHANGED
                initAll(tags, UNCHANGED)
                initAll(organizationEventOccurrences, UNCHANGED)

                this.setState({ topLoader: false, item: organizationEvent, eventOrganizations: eventOrganizations, organizationEventOccurrences: organizationEventOccurrences, loading: false, error: null, tags: tags, event: organizationEvent })
                this.getImage();
                const canBeDeleted = eventCanBeDeleted(organizationEvent?.organization_event_occurrences)
                this.setState({eventDelButtonDisabled: !canBeDeleted})
            }, (error) => {
                let errorMessage = parseErrorMessage(error)
                this.setState({ error: errorMessage })
            })
        } else {
            this.setState({ item: { organization_id: 0, title: null, segment_id: this?.state?.segmentOptions?.[0]?.value, _action: NEW }, organizationEventOccurrences: [], loading: false, error: null })
        }
    }

    handleFileUpload = (uploadedFiles, imageKey) => {
        // Handle the uploaded files here
        let orgId = '0'

        postMediaDragDrop(uploadedFiles, orgId, (media) => {
            this.onChange(media[0].id, imageKey)
        }, (error) => {
            var errorMessage = parseErrorMessage(error)
            this.setState({ filesUploadError: errorMessage })
            console.log("Post file error", error)
        })
    }

    onChange(value, key) {
        var item = this.state.item
        item[key] = value
        onEditObject(item)
        this.setState({ item: item, itemEdited: true })
    }

    handleFileRemove = (imageKey) => {
        this.onChange(null, imageKey)
    }

    getImage() {
        getMediaImage(this.state.item.media_id, (image) => {
            this.setState({ currentImage: image })
        }, (error) => {
            console.log(error)
        })

        getMediaImage(this.state.item.media_page_id, (image) => {
            this.setState({ currentPageImage: image })
        }, (error) => {
            console.log(error)
        })
    }
    
    getItem() {
        let id = this.props.match.params.id
        if (id) {

            getEventSegments(id, (result) => {
                var segmentOptions = []
                if (result?.length) {
                    const segments = result.sort((a, b) => a.id - b.id);
                    for (let segment of segments) {
                        segmentOptions.push({ value: segment.id, label: segment.name })
                    }
                }
                this.setState({eventSegmentOptions: segmentOptions})
            }, (error) => {
                const errorMessage = parseErrorMessage(error)
                this.setState({error: errorMessage, loading: false})
            })
        }

        getSegments(0, '', (result) => {
            if (result.segments) {
                var segmentOptions = []

                // union users should not see "Everyone" (null) segment
                getCurrentUser((user) => {

                    const segments = result.segments.sort((a, b) => a.id - b.id);

                    segmentOptions.push({ value: null, label: 'Everyone' })

                    for (let segment of segments) {
                        segmentOptions.push({ value: segment.id, label: segment.name })
                    }

                    const ticketSegmentOptions = getTicketSegmentOptions(user, segments)
                    this.setState({ organizationId: user.organization_id, segmentOptions: segmentOptions, ticketSegmentOptions: ticketSegmentOptions, id: id }, () => {
                        this.getEvent(id)
                    })

                }, (error) => {
                    const errorMessage = parseErrorMessage(error)
                    this.setState({error: errorMessage, loading: false})
                })

            }
        }, (error) => {
            const errorMessage = parseErrorMessage(error)
            this.setState({error: errorMessage, loading: false})
        })

        getAllForms((forms) => {
            let formOptions = []
            forms.forEach((form) => {
                formOptions.push({value: form.id, label: form.name})
            })
            this.setState({formOptions: formOptions})
        }, (error) => {
            console.log(error)
        })

        getInterests(0, null, ['created_at DESC'], [{relation: 'organization_event_occurrences'}], (interests, totalCount) => {
            let interestOptions = []
            let allInterests = []
            if (interests?.length > 0) {
                // only add interests that has no connection to occurrences
                interests.forEach((interest) => {
                    if (!interest?.organization_event_occurrences || interest.organization_event_occurrences.length <= 0) {
                        interestOptions.push({value: interest.id, label: interest.name})
                        allInterests.push(interest)
                    }
                })
            }
            this.setState({interestOptions: interestOptions, allInterests: allInterests})
        }, (error) => {
            console.log(error)
        })

        // getAllCampaigns 
        getCampaigns(
            (campaigns) => {
                const formattedCampaigns = campaigns.map(campaign => ({
                    value: campaign.id,
                    label: campaign.title
                  }));
                this.setState({ campaigns: formattedCampaigns });
            },
            (error) => {
                console.error('Error fetching campaigns:', error);
            }
        )
        
    }
    

    toggleOccurrenceModal() {
        if (this.state.showOccurrenceModal) {
            this.setState({showOccurrenceModal: false, editOccurrence: null, clonedOccurrence: null, clonedTickets: null, clonedInterests: null})
        }
    }

    onEditOrganizationEventOccurrenceGuests(occurrenceId, guests) {
        var organizationEventOccurrences = this.state.organizationEventOccurrences
        if (!organizationEventOccurrences || organizationEventOccurrences.length <= 0) {
            return
        }

        var organizationEventOccurrence = organizationEventOccurrences.find((occurrence) => occurrence.id === occurrenceId)
        if (!organizationEventOccurrence) {
            return
        }

        // update guest list so it shows the correct count after editing guests
        organizationEventOccurrence.organization_event_occurrence_guests = guests
        this.setState({organizationEventOccurrences: organizationEventOccurrences})
    }

    renderEventAttendees() {
        var organizationEvent = this.state.event
        var occurrence = this.state.occurrence

        if (!occurrence) {
            return null
        }
        return <>
            <EventAttendees organizationEvent={organizationEvent} onEditGuests={this.onEditOrganizationEventOccurrenceGuests.bind(this)} id={organizationEvent.id} eventId={organizationEvent.id} occurrenceId={occurrence.id} toggle={this.toggleEventAttendeesModal} isOpen={this.isAttendeesModalOpen()}/>
        </>
    }

    renderOccurrences(organizationEvent) {

        if (!organizationEvent) {
            return null
        }

        return <React.Fragment>
            <OccurrenceList 
                organizationEvent={organizationEvent} 
                organizationEventOccurrences={this.state.organizationEventOccurrences}
                onDeleteOccurrence={this.onDeleteOccurrence.bind(this)}
                onCloneOccurrence={this.onCloneOccurrence.bind(this)}
                onClickEditOccurrence={this.onClickEditOccurrence.bind(this)} 
                toggleEventAttendeesModal={this.toggleEventAttendeesModal.bind(this)}
                onClickNotify={this.onClickNotify.bind(this)}
                onSaveOccurrence={this.onSaveOccurrence.bind(this)} />

            {this.state.attendeeModalOpen && this.renderEventAttendees()}
        </React.Fragment>

    }
    
    toggleEventAttendeesModal(occurrence) {
        this.setState(prevState => ({
            attendeeModalOpen: !prevState.attendeeModalOpen,
            occurrence: occurrence
        }))
      }

    isAttendeesModalOpen() {
        return this.state.attendeeModalOpen
    }

    onClickEditOccurrence(occurrence) {
        this.setState({showOccurrenceModal: true, editOccurrence: occurrence})
    }

    onClickNotify(event, occurrence) {
        this.setState({showOccurrencePushModal: true, occurrenceToPush: occurrence, eventToPush: event})
    }

    toggleOccurrencePushModal() {
        if (this.state.showOccurrencePushModal) {
            this.setState({showOccurrencePushModal: false, occurrenceToPush: null})
        }
    }

    renderEditOccurrenceModal(organizationEvent) {
        if (!this.state.showOccurrenceModal) {
            return null
        }
        return <EditOccurrence clonedTickets={this.state.clonedTickets} clonedInterests={this.state.clonedInterests} clonedOccurrence={this.state.clonedOccurrence} interests={this.state.allInterests} onAddOccurrence={(occurrence) => this.onSaveOccurrence(occurrence)} event={organizationEvent} occurrence={this.state.editOccurrence} campaigns={this.state.campaigns} formOptions={this.state.formOptions} segmentOptions={this.state.ticketSegmentOptions} toggle={this.toggleOccurrenceModal.bind(this)} />
    }

    async onDeleteNotification(notification, onSuccess, onError) {
        deleteNotificationById(notification.id, () => {
            onSuccess()
        }, (error) => {
            var errorMessage = "Could not delete push notification: " + parseErrorMessage(error)
            onError(errorMessage)
        })
    }

    async onSaveNotification(notification, onSuccess, onError) {

        if (notification.new) {
            createOrganizationEventOccurrenceNotification(notification.organization_event_occurrence_id, notification, (result) => {
                onSuccess()
            }, (error) => {
                var errorMessage = "Could not create push notification: " + parseErrorMessage(error)
                onError(errorMessage)
            })
        } else {
            var data = {
                id: notification.id,
                title: notification.title,
                text: notification.text, 
                schedule: notification.schedule, 
                segment_id: notification.segment_id
            } 
          
            updateNotificationById(data,  (result) => {   
                onSuccess()
            }, (error) => {
                var errorMessage = "Could not update push notification: " + parseErrorMessage(error)
                onError(errorMessage)
            })  
        }
        
    }

    renderFormFooter() {
        let footer = super.renderFormFooter()
        return <>
            {footer}
        </>
    }

    onCloneOccurrence(occurrence, tickets, interests) {
        if (!occurrence) {
            return 
        }

        const clonedOccurrence = clone(occurrence, ["start_date", "end_date", "city", "location", "organization_event_id", "street_address", "zip_code", "options"], () => {return {key: uuidv4(), isClone: true, _action: NEW}})
        const clonedTickets = cloneAll(tickets, ["name", "count", "max_count_per_member", "currency", "price", "start_date", "end_date", "segment_id", "type", "ticket_forms", "campaigns"], () => {return {key: uuidv4(), occurrence_key: clonedOccurrence.key, isClone: true, _action: NEW}})
        const clonedInterests = cloneAll(interests, ["id", "name", "name_en", "start_date", "end_date", "segment_id", "description", "description_en", "short_description", "short_description_en", "organization_id", "interest_forms"], () => {return {key: uuidv4(), occurrence_key: clonedOccurrence.key, isClone: true, _action: NEW}})

        this.setState({showOccurrenceModal: false, editOccurrence: null}, () => {
            this.setState({showOccurrenceModal: true, editOccurrence: null, clonedOccurrence: clonedOccurrence, clonedTickets: clonedTickets, clonedInterests: clonedInterests})
        }) 
    } 

    onDeleteOccurrence(occurrence) {
        var organizationEventOccurrences = this.state.organizationEventOccurrences
        removeItem(organizationEventOccurrences, occurrence)
        this.setState({organizationEventOccurrences: organizationEventOccurrences})
    }

    onSaveOccurrence(occurrence) {
        var organizationEventOccurrences = this.state.organizationEventOccurrences
        setItem(organizationEventOccurrences, occurrence)
        sortByDate(organizationEventOccurrences, 'DESC')
        this.setState({organizationEventOccurrences: organizationEventOccurrences})
    }

    onAddEventOrganization = (eventOrganization) => {
        const eventOrganizations = this.state.eventOrganizations
        onAddObject(eventOrganization)
        eventOrganizations.push(eventOrganization)
        this.setState({eventOrganizations: eventOrganizations})
    }

    onDeleteEventOrganization = (eventOrganization) => {
        const eventOrganizations = this.state.eventOrganizations
        removeItem(eventOrganizations, eventOrganization)
        this.setState({eventOrganizations: eventOrganizations})
    }

    onAddTag = (organizationDetailTag) => {
        const tags = this.state.tags
        onAddObject(organizationDetailTag)
        tags.push(organizationDetailTag)
        this.setState({tags: tags})
      }
 
    onDeleteTag = (organizationEventTag) => {
        const tags = this.state.tags
        removeItem(tags, organizationEventTag)
        this.setState({tags: tags})
    }

    renderEventCreatedBy() {
        if (!this.state?.organizationId) {
            return null
        }

        if (!this.state.item?.organization_id || !this.state?.item?.organization) {
            return null
        }

        if (this.state.organizationId === this.state.item?.organization_id) {
            return null
        }

        return <div className='mb-4'>
            <p className="list-item-heading mb-1">Event Owner</p>
            <p className="list-item-text mb-0">{this.state?.item?.organization?.display_name}</p>
        </div>
    }

    getSegmentOptions(organizationEvent) {
        const segments = this.state?.segmentOptions ? [...this.state?.segmentOptions] : []
        addBaseSegmentIfMissing(organizationEvent, segments)
        return segments
    }

    renderForm(organizationEvent) {
        const organizationDisplayName = this.state?.item?.organization?.display_name
        return <Form>
            {this.state.topLoader ? <Loader type={"dots"} /> : null}
            <Row>
                <Col className="col-12 col-md-8">

                    {this.renderEventCreatedBy()}

                    <FormGroup>
                        <MultiLanguageInput label={'Title'} keys={titleLanguageKeys} data={toMultiLanguageData(organizationEvent, titleLanguageKeys)} onChange={(data, key) => this.onChange(data, key)} isInvalid={this.isFieldInvalid('title')} />
                    </FormGroup>

                    <FormGroup>
                        <MultiLanguageNoImageClassicEditor label={'Description'} keys={contentLanguageKeys} data={toMultiLanguageData(organizationEvent, contentLanguageKeys)} onChange={(data, key) => this.onChange(data, key)} />
                    </FormGroup>

                    {this.state.showOccurrencePushModal && <OccurrenceNotifications occurrence={this.state.occurrenceToPush} event={this.state.eventToPush} segmentOptions={this.state.eventSegmentOptions} onSaveNotification={(notification, onSuccess, onError) => this.onSaveNotification(notification, onSuccess, onError)} onDeleteNotification={(notification, onSuccess, onError) => this.onDeleteNotification(notification, onSuccess, onError)} toggle={this.toggleOccurrencePushModal.bind(this)} />} 
                    {this.renderOccurrences(organizationEvent)}
                    {this.renderEditOccurrenceModal(organizationEvent)}

                </Col>

                <Col className="col-12 col-md-4">

                    <FormGroup>
                        <Label for="segment">Segment - Who can see this event?</Label>

                        <div className="row">
                            <div className='col-12'>
                                <Select options={this.getSegmentOptions(organizationEvent)} onChange={(e) => this.onChange(e.value, 'segment_id')} placeholder={"Select segment..."} value={this.getSegmentOptions(organizationEvent).filter((option) => option.value === organizationEvent.segment_id)} />
                            </div>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <div className="row">
                            <div className='col-12'>
                                <Label for="">Tags</Label>
                                <OrganizationTags
                                    tags={this.state.tags}
                                    includeTagOptionIds={EVENT_TAG_IDS}
                                    onAddTag={this.onAddTag}
                                    onDeleteTag={this.onDeleteTag} />
                            </div>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <div className="row">
                            <div className='col-12'>
                                <Label for="">Co host</Label>
                                <SimpleHelpPopover color="info" placement="left">
                                    Selected organizations will have access to view and edit this event. 
                                    Only the event owner {organizationDisplayName ? `(${organizationDisplayName})` : ''} will receive payouts for transactions for this event.
                                </SimpleHelpPopover>
                                <OrganizationEventCoHosts
                                    disabled={!!(this.state?.item?.organization_id && this.state?.item?.organization_id !== this.state?.organizationId)}
                                    eventOrganizations={this.state.eventOrganizations}
                                    organizationId={this.state?.organizationId}
                                    onAddEventOrganization={this.onAddEventOrganization}
                                    onDeleteEventOrganization={this.onDeleteEventOrganization} />
                            </div>
                        </div>
                    </FormGroup>

                    <Label for="image">Event feed image</Label>
                    <FileUpload ratio={"2/1"} onFileUpload={(e) => this.handleFileUpload(e, 'media_id')} onFileRemove={() => this.handleFileRemove('media_id')} currentImage={this.state.currentImage} />
                    <FormGroup className="d-flex justify-content-between">
                        <MediaSelect files={this.state.files} item={organizationEvent} index={0} mediaId={organizationEvent.media_id} itemId={organizationEvent.id}
                            onChange={(id, item, index) => { this.onChange(id, 'media_id'); this.getImage() }} onDelete={(itemId, index) => this.onChange(null, 'media_id')} />
                        <p className="mini-font">Recommended size 1920x960px</p>                            
                    </FormGroup>
            
                    <Label for="image">Event page image</Label>
                    <FileUpload ratio={"3/2"} onFileUpload={(e) => this.handleFileUpload(e, 'media_page_id')} onFileRemove={() => this.handleFileRemove('media_page_id')} currentImage={this.state.currentPageImage} />
                    <FormGroup className="d-flex justify-content-between">
                        <MediaSelect files={this.state.files} item={organizationEvent} index={0} mediaId={organizationEvent.media_page_id} itemId={organizationEvent.id}
                            onChange={(id, item, index) => { this.onChange(id, 'media_page_id'); this.getImage() }} onDelete={(itemId, index) => this.onChange(null, 'media_page_id')} />
                        <p className="mini-font">Recommended size 1920x1280px</p>                            
                    </FormGroup>

                </Col>
            </Row>
        </Form>
    }

    hasDeleteButton() {
        return true
    }

    onDelete() {

        let confirm = window.confirm("Are you sure you want to delete this event?")
        if (!confirm) {
            return
        }

        let data = {
            status: PUBLISH_STATUS_ARCHIVED
        }
        patchOrganizationEvent(this.state.item.id, data, () => {
            setTimeout(() => {
                this.props.history.replace('/organization-events')
            }, 1000)
        }, (error) => {
            this.onFormError(error)
        })
    }

    onSave(status) {
        this.saveOrganizationEvent(status, () => {
            this.onFormSuccess()
            this.setState({loading: false})
        }, (error) => {
            this.setState({loading: false})
            this.onFormError(error)
        })
    }

    async deleteAllTicketFormsByTicket(ticketId) {
        const ticketsForms = await promisifyOrFalse(getTicketsForms, ticketId)

        // delete all old tickets -> form relations
        if (ticketsForms?.length > 0) {
            for (const ticketsForm of ticketsForms) {
                await promisifyOrFalse(deleteFormFromTicket, ticketsForm.id)
            }
        }
    }

    async deleteAllInterestFormsByInterest(interestId) {
        const interestForms = await promisifyOrFalse(getInterestForms, interestId)

        // delete all old tickets -> form relations
        if (interestForms?.length > 0) {
            for (const interestForm of interestForms) {
                await promisifyOrFalse(deleteFormFromInterest, interestForm.id)
            }
        }
    }

    async saveOptions(organizationEventOccurrence) {
        if (!organizationEventOccurrence.options || organizationEventOccurrence.options.length <= 0) {
            return
        }

        for (const option of organizationEventOccurrence.options) {
            if (option?._action === NEW) {
                const data = clone(option, ["organization_event_occurrence_id", "key", "value"], () => {return {organization_event_occurrence_id: organizationEventOccurrence.id}})
                await promisifyOrFalse(createOccurrenceOption, organizationEventOccurrence.id, data)
            }

            if (option?._action === EDIT) {
                const data = clone(option, ["organization_event_occurrence_id", "key", "value"], () => {return {organization_event_occurrence_id: organizationEventOccurrence.id}})
                await promisifyOrFalse(patchOccurrenceOption, organizationEventOccurrence.id, option.id, data)
            }
        }
    }

    async saveInterests(organizationEventOccurrence) {
        if (!organizationEventOccurrence.interests || organizationEventOccurrence.interests.length <= 0) {
            return
        }

        // first delete all old
        for (var interest of organizationEventOccurrence.interests) {
            if (interest?._action === DELETE) {
                const where = {
                    organization_event_occurrence_id: organizationEventOccurrence.id,
                    interest_id: interest.id
                }
                await promisifyOrFalse(deleteEventOccurrenceInterests, where)
            }

            if (interest?._action === NEW) {
                await promisifyOrFalse(createEventOccurrenceInterest, organizationEventOccurrence.id, interest.id)
            }
        }
    }

    async saveTickets(organizationEventOccurrence) {

        if (!organizationEventOccurrence.tickets || organizationEventOccurrence.tickets.length <= 0) {
            return
        }

        for (var ticket of organizationEventOccurrence.tickets) {

            if (ticket?._action === NEW) {
                const data = clone(ticket, ["name", "count", "start_date", "end_date", "max_count_per_member", "price", "currency", "type", "segment_id"], () => {return {}})
                const createTicketsResult = await promisifyOrFalse(createTickets, data)
                ticket.id = createTicketsResult.id
                await promisifyOrFalse(createEventOccurrenceTickets, organizationEventOccurrence.id, createTicketsResult.id)
            }

            if (ticket?._action === EDIT) {
                const data = clone(ticket, ["name", "count", "start_date", "end_date", "max_count_per_member", "price", "currency", "type", "segment_id"], () => {return {}})
                await promisifyOrFalse(updateTicketsById, ticket.id, data)
            }

            if (ticket?._action === DELETE) {
                await this.deleteAllTicketFormsByTicket(ticket.id)
                await promisifyOrFalse(deleteTickets, ticket.id)
            }

            // ticket form
            await this.saveTicketForms(ticket)
            await this.saveTicketCampaign(ticket)
        }
    }

    async saveTicketForms(ticket) {

        if (!ticket.ticket_forms || ticket.ticket_forms.length <= 0) {
            return
        }

        for (const ticketForm of ticket.ticket_forms) {
            if (ticketForm?._action === NEW) {
                await promisifyOrFalse(addFormToTicket, ticket.id, ticketForm.form_id)
            }

            if (ticketForm?._action === DELETE) {
                await promisifyOrFalse(deleteFormFromTicket, ticketForm.id)
            }
        }
    }

    async saveInterestForms(interest) {

        if (!interest.interest_forms || interest.interest_forms.length <= 0) {
            return
        }

        for (const interestForm of interest.interest_forms) {
            if (interestForm?._action === NEW) {
                await promisifyOrFalse(addFormToInterest, interest.id, interestForm.form_id)
            }

            if (interestForm?._action === DELETE) {
                await promisifyOrFalse(deleteFormFromInterest, interestForm.id)
            }
        }
    }

    async saveTicketCampaign(ticket) {
        const prevCampaignId = ticket.prev_campaign_id;
        const newCampaignId = ticket.campaign_id;
      
 
        if (prevCampaignId === newCampaignId) {
            return;
        }

        if (prevCampaignId && newCampaignId === null) {
            deleteCampaignFromTicketPackage(
                ticket.id, 
                null,
                prevCampaignId, 
                (response) => {
                }, 
                (error) => {
                    console.error("Error delete campaign to the ticket", error);
                }
            );
        }
        if (!prevCampaignId && newCampaignId) {
            addCampaignToTicketPackage(
                ticket.id, 
                null,
                ticket.campaign_id, 
                (response) => {
                }, 
                (error) => {
                    console.error("Error adding campaign to the ticket", error);
                }
            );
        }

        if (prevCampaignId && newCampaignId && prevCampaignId !== newCampaignId) {
            deleteCampaignFromTicketPackage(
                ticket.id, 
                null,
                prevCampaignId, 
                (response) => {
                }, 
                (error) => {
                    console.error("Error delete campaign to the ticket", error);
                }
            );
            addCampaignToTicketPackage(
                ticket.id, 
                null,
                newCampaignId, 
                (response) => {
                }, 
                (error) => {
                    console.error("Error adding campaign to the ticket", error);
                }
            );
        }
    }

    async saveEventOrganizations(organizationEventId) {
        const eventOrganizations = this.state?.eventOrganizations
        if (!eventOrganizations || eventOrganizations.length <= 0) {
            return
        }

        for (const eventOrganization of eventOrganizations) {
            if (eventOrganization?._action === NEW) {
                await promisifyOrFalse(createEventOrganization, eventOrganization.organization_id, organizationEventId)
            }
            
            if (eventOrganization?._action === DELETE) {
                await promisifyOrFalse(deleteEventOrganization, eventOrganization.id)
            }
        }
    }

    async saveTags(organizationEventId) {

        const tags = this.state?.tags
        if (!tags || tags.length <= 0) {
            return
        }

        for (const tag of tags) {
            if (tag?._action === NEW) {
                await promisifyOrFalse(createOrganizationEventTag, tag.organization_tag_id, organizationEventId)
            }
            
            if (tag?._action === DELETE) {
                await promisifyOrFalse(deleteOrganizationEventTag, tag.id)
            }
        }
    }

    async saveOrganizationEventOccurrences(organizationEventId) {

        if (!this.state?.organizationEventOccurrences || this.state.organizationEventOccurrences.length <= 0) {
            return
        }

        for (var organizationEventOccurrence of this.state.organizationEventOccurrences) {
            if (organizationEventOccurrence?._action === NEW) {
                // Add total_capacity to the cloned fields
                const data = clone(organizationEventOccurrence, 
                    ["location", "start_date", "end_date", "zip_code", "street_address", "city", "total_capacity"], 
                    () => {return {organization_event_id: organizationEventId}}
                )
                
                const result = await promisifyOrFalse(createOrganizationEventOccurrence, data)
                if (!result) {
                    // error
                }
                organizationEventOccurrence.id = result.id
            }

            if (organizationEventOccurrence?._action === EDIT) {
                // Add total_capacity to the cloned fields
                const data = clone(organizationEventOccurrence, 
                    ["location", "start_date", "end_date", "zip_code", "street_address", "city", "total_capacity"], 
                    () => {return {organization_event_id: organizationEventId}}
                )
                await promisifyOrFalse(updateOrganizationEventOccurrence, organizationEventOccurrence.id, data)
            }

            if (organizationEventOccurrence?._action === DELETE) {
                if (!occurrenceCanBeDeleted(organizationEventOccurrence)) {
                    continue;
                }

                const where = {
                    organization_event_occurrence_id: organizationEventOccurrence.id
                }
                await promisifyOrFalse(deleteEventOccurrenceInterests, where)

                // delete all connected tickets (will only work if none are purchased)
                if (organizationEventOccurrence?.tickets?.length > 0) {
                    for (let ticket of organizationEventOccurrence.tickets) {
                        await this.deleteAllTicketFormsByTicket(ticket.id)
                        await promisifyOrFalse(deleteTickets, ticket.id)
                    }
                }

                // delete all connected interests (will only work if none are purchased)
                if (organizationEventOccurrence?.interests?.length > 0) {
                    for (const interest of organizationEventOccurrence.interests) {
                        await this.deleteAllInterestFormsByInterest(interest.id)
                    }
                }

                await promisifyOrFalse(deleteOrganizationEventOccurrence, organizationEventOccurrence.id)
            }

            await this.saveOptions(organizationEventOccurrence)
            await this.saveTickets(organizationEventOccurrence)
            await this.saveInterests(organizationEventOccurrence)
        }
    }

    async saveOrganizationEvent(status, onSuccess, onError) {

        this.setState({topLoader: true})

        var event = {...this.state.item}

        if (event?._action === NEW) {
            const data = clone(event, ["organization_id", "title", "title_en", "sub_title", "media_id", "content", "content_en", "description", "segment_id", "status", "media_page_id"], () => {return {status: status}})
            createOrganizationEvent(data, async (result) => {
                await this.onEventSaved(result.id, event?._action, onSuccess)
            }, (error) => {
                onError(error)
            })
        } else {
            var now = formatDate()
            const data = clone(event, ["organization_id", "title", "title_en", "sub_title", "media_id", "content", "content_en", "description", "segment_id", "status", "media_page_id"], () => {return {updated_at: now, status: status}})
            patchOrganizationEvent(event.id, data, async () => {
                await this.onEventSaved(event.id, event?._action, onSuccess)
            }, (error) => {
                onError(error)
            })
        } 
    }

    async onEventSaved(id, action, onSuccess) {
        await this.saveOrganizationEventOccurrences(id)
        await this.saveTags(id)
        await this.saveEventOrganizations(id)
        onSuccess()

        if (action === NEW) {
            this.props.history.replace('/organization-events/edit/' + id)
        } else {
            this.getItem()
        }
    }

}
export default EditOrganizationEvent
