import TimePicker from 'rc-time-picker';
import React from 'react';
import moment from 'moment'
import { Input } from 'reactstrap';

export default class DateTimePicker extends React.Component {

    formatDate(date) {
        return date ? date.substr(0, 10) : ''
    }

    onChangeDate(value, key) {
        var time = this.getTimeString(this.props.value)
        let datetime
        if (!value) {
            datetime = null
        } else {
            datetime = value + 'T' + time
        }
        this.props.onChange(datetime, key)
    }

    onChangeTime(value, key) {
        if (this.props.value) {
            let date = this.props.value.split('T')[0]
            let time = moment(value).format('HH:mm:ss') + '.000Z'
            let datetime = date + 'T' + time
            this.props.onChange(datetime, key)
        }
    }

    getTimeString(date) {
        if (date) {
            return date.split('T')[1]
        }
        return '00:00:00.000Z'
    }

    getTimeMoment(date) {
        if (date) {
            return moment(date.split('T')[1].split('.')[0], 'HHmmss')
        }
        return moment('00:00:00', 'HHmmss')
    }

    render() {

        return <div className='d-flex'>
            <Input className="all-caps" min={new Date().toISOString().split("T")[0]} type="date" name={this.props.name} id={this.props.name} defaultValue={this.formatDate(this.props.value)} onChange={(e) => this.onChangeDate(e.target.value, this.props.name)} />
            {!this.props.hideTime &&
                <TimePicker disabled={this.props.value ? false : true} className='ml-2' showSecond={false} onChange={(e) => this.onChangeTime(e, this.props.name)} placeholder="HH:MM" value={this.getTimeMoment(this.props.value)} allowEmpty={false} inputReadOnly />
            }
        </div>
    }

}