import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink,
    Card, Button, UncontrolledAlert} from 'reactstrap';
import { getCurrentUser, getOrganizationById , patchUser, changeUserPassword, forceRefreshToken} from '../../../vibe/helpers/apiHelper';
import classnames from 'classnames';
import UserForm from '../../elements/users/UserForm';
import UserFormChangePassword from '../../elements/users/UserFormChangePassword';
import { Loader } from '../../../vibe';
import ApiKeys from '../../elements/api-keys/ApiKeys';

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: '1',
            forSubmit: false,   
            current_password: '',                     
            new_password: '',
            confirm_new_password: '',
            isError: {
              current_password: '',
              new_password: '',
              confirm_new_password: '',
              passwordMatch: '',
            }
        }

        this.toggle = this.toggle.bind(this)
    }

    componentDidMount() {
        getCurrentUser((user) => {
            if (user.organization_id) {
                getOrganizationById(user.organization_id, (organization) => {
                    this.setState({ user: user, error: null, organization: organization })
                }, (error) => {
                    this.setState({ user: user, error: null, organization: null })
                })
            } else {
                this.setState({ user: user, error: null, organization: null })
            }
        }, (error) => {
            this.setState({ error: error, user: null })
        })
    }


    renderAlerts() {
        if (this.state) {
            if (this.state.userSaved) {
                return <UncontrolledAlert color="success">
                    User saved!
                </UncontrolledAlert>
            } else if (this.state.userSaveError) {
                return <UncontrolledAlert color="danger">
                    Error saving user, contact support [{this.state.userSaveError}]
                </UncontrolledAlert>
            }
        }
    }

    onChange(e, key) {
        var user = this.state.user
        user[key] = e.target.value
        this.setState(user)
        this.setState({ userEdited: true })
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    onSaveUser() {
        var userData = {
            first_name: this.state.user.first_name,
            last_name: this.state.user.last_name
        }

        if (this.state.userEdited) {
            patchUser(userData, 
                ()=>{
                    this.setState({ userSaved: true })
                    forceRefreshToken()
                }, 
                (error)=>{
                    this.setState({ userSaved: false, userSaveError: error })
                }
            )
        }
    }   

    onUpdatePassword(){
        this.setState({ forSubmit: true })
 
        var validate = this.validateOnSubmit()
        if (validate) {
            var userPasswords = {
                current_password: this.state.current_password,
                new_password: this.state.new_password,
                confirm_new_password: this.state.confirm_new_password
            }     
            if(this.state.userPasswordEdited){
                changeUserPassword(userPasswords, 
                    ()=>{
                        this.setState({ userSaved: true })
                        document.getElementById("changePassword").reset();
                    }, 
                    (error)=>{
                        this.setState({ userSaved: false, userSaveError: error })
                    }
                ) 
            }           
        }
    }

    validateOnSubmit() {
        let isError = { ...this.state.isError };
    
        if (this.state.new_password.length === 0) {
          isError.new_password = 'Password is required';
        }
        if (this.state.confirm_new_password.length === 0) {
          isError.confirm_new_password = 'Password is required';
        }
        if (this.state.confirm_new_password !== this.state.new_password) {
          isError.passwordMatch = 'Password does\'t match.';
        } else {
          isError.passwordMatch = ''
        }
    
        this.setState({
          isError,
        })
    
        return isError.new_password.length === 0 && isError.confirm_new_password.length === 0 && isError.passwordMatch.length === 0
      }
   

    onChangePassword(e, key){
        e.preventDefault();
        
        this.setState({ userPasswordEdited: true })  

        const { name, value } = e.target;
        let isError = { ...this.state.isError };
    
        switch (name) {
          case "new_password":
            isError.new_password = value.length < 8 ? "Password is required" : "";
            isError.passwordMatch = value !== this.state.confirm_new_password ? "Password does't match." : "";
            break;
          case "confirm_new_password":
            isError.confirm_new_password = value.length < 8 ? "Password is required" : "";
            isError.passwordMatch = value !== this.state.new_password ? "Password does't match." : "";
            break;
          default:
            break;  
        }      

        this.setState({
            isError,
            [name]: value
        })        
    }



    render() {
        const { isError, new_password, confirm_new_password , forSubmit} = this.state;

        if (this.state && this.state.error) {
            return <div>ERROR: {this.state.error.message}</div>
        } else if (this.state && this.state.user) {
            return (

            <React.Fragment>
                {this.renderAlerts()}

                <Card>
                    <div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink href="#" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1') }}>
                                    Profile
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2') }}>
                                    Account security
                                </NavLink>
                            </NavItem>
                            {this.state.user.role === "AdminVerifyStudent" &&
                                <NavItem>
                                    <NavLink href="#" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3') }}>
                                        API Keys
                                    </NavLink>
                                </NavItem>
                            }

                        </Nav>

                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <UserForm user={this.state.user} onChange={this.onChange.bind(this)} />
                            </TabPane>
                            <TabPane tabId="2">
                                <UserFormChangePassword user={this.state.user} isError={isError} new_password={new_password} confirm_new_password={confirm_new_password} forSubmit={forSubmit} onChangePassword={this.onChangePassword.bind(this)} />                              
                            </TabPane>
                            <TabPane tabId="3">
                                <ApiKeys />
                            </TabPane>
                        </TabContent>
                    </div>
                    {this.state.activeTab !== '3' && (
                    <Button color="success edit-button" onClick={() => {
                        if (this.state.activeTab === '1') {
                            this.onSaveUser();
                        } else if (this.state.activeTab === '2') {
                            this.onUpdatePassword();
                        }
                        // No action needed for tab 3 as ApiKeys component has its own buttons
                    }}><i className="fa fa-check"></i>&nbsp;Save</Button> 
                    )}
                </Card>
            </React.Fragment>

            )
        } else {
            return <Loader type="dots" />
        }
    }

}

export default Profile;
