import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';

class OrganizationEvent extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    capitalize(str){
        if(str){
            const lower = str.toLowerCase()
            return str.charAt(0).toUpperCase() + lower.slice(1)    
        }
    }
    
    render() {
        if (!this.props.organizationEvent) {
            return null
        }

        const isCohost = this.props.organizationEvent?.organization_id !== this.props?.user?.organization_id

        return <Link className="member-card-link" to={'/organization-events/edit/' + this.props.organizationEvent.id} key={this.props.organizationEvent.id}>
                <Card className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                    <div className="mb-0 text-small w-50 w-sm-100">
                        <p className="list-item-heading mb-1">Title</p>
                        <p className="list-item-text mb-0">{this.props.organizationEvent.title}</p>
                    </div>
                    <div className="mb-0 text-small w-50 w-sm-100">
                        <p className="list-item-heading mb-1">Status</p>
                        <p className="list-item-text mb-0">{this.capitalize(this.props.organizationEvent.status)}</p>
                    </div>
                    <div className="mb-0 text-small w-50 w-sm-100">
                        <p className="list-item-heading mb-1">Created Date</p>
                        <p className="list-item-text mb-0">{new Date(this.props.organizationEvent.created_at).toISOString().split('T')[0]}</p>
                    </div>
                    <div className="mb-0 text-small w-25 w-sm-100">
                        <p className="list-item-heading mb-1">Updated Date</p>
                        <p className="list-item-text mb-0">{new Date(this.props.organizationEvent.updated_at).toISOString().split('T')[0]}</p>
                    </div>

                    <div className="mt-0 text-small w-25 w-sm-100">
                        {isCohost && <>
                            <p className="list-item-heading mb-1">Event Owner</p>
                            <p className="list-item-text mb-0">{this.props.organizationEvent?.organization?.display_name}</p>
                        </>}
                    </div>

                </Card>
            </Link>
    }

}
export default OrganizationEvent
