import React, { Component } from 'react';
import { Card, Button, Alert } from 'reactstrap';
import { getApiKeys, createApiKey, deleteApiKey } from '../../../vibe/helpers/apiHelper';
import { Loader } from '../../../vibe';

class ApiKeys extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      apiKeys: [],
      loading: true,
      error: null,
      success: null
    };

    this.generateApiKey = this.generateApiKey.bind(this);
    this.deleteApiKey = this.deleteApiKey.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  componentDidMount() {
    this.fetchApiKeys();
  }

  fetchApiKeys() {
    this.setState({ loading: true });
    getApiKeys(
      (apiKeys) => {
        this.setState({ apiKeys, loading: false, error: null });
      },
      (error) => {
        this.setState({ 
          error: typeof error === 'object' ? error.message : error, 
          loading: false 
        });
      }
    );
  }

  generateApiKey() {
    const hasActiveKey = this.state.apiKeys.some(key => key.is_active);

    // Show confirmation dialog if user already has an active key
    if (hasActiveKey) {
      if (!window.confirm('You already have an active API key. Generating a new key will invalidate your existing key. Are you sure you want to continue?')) {
        return;
      }
    }

    const apiKeyData = {
      name: 'API Key'
    };

    createApiKey(
      apiKeyData,
      (result) => {
        this.fetchApiKeys();
        this.setState({
          error: null,
          success: `API key ${hasActiveKey ? 'regenerated' : 'generated'} successfully`
        });

        setTimeout(() => {
          this.setState({ success: null });
        }, 3000);
      },
      (error) => {
        this.setState({ 
          error: typeof error === 'object' ? error.message : error 
        });
      }
    );
  }

  deleteApiKey(id) {
    if (!window.confirm('Are you sure you want to delete this API key?')) {
      return;
    }

    deleteApiKey(
      id,
      () => {
        this.setState({
          apiKeys: this.state.apiKeys.filter(key => key.id !== id),
          success: 'API key deleted successfully'
        });

        setTimeout(() => {
          this.setState({ success: null });
        }, 3000);
      },
      (error) => {
        this.setState({ 
          error: typeof error === 'object' ? error.message : error 
        });
      }
    );
  }

  copyToClipboard(text) {
    navigator.clipboard.writeText(text)
      .then(() => {
        this.setState({ success: 'API key copied to clipboard' });
        
        setTimeout(() => {
          this.setState({ success: null });
        }, 3000);
      })
      .catch(() => {
        this.setState({ error: 'Failed to copy API key' });
      });
  }

  renderAlerts() {
    if (this.state.error) {
      return <Alert color="danger">{this.state.error}</Alert>;
    }
    if (this.state.success) {
      return <Alert color="success">{this.state.success}</Alert>;
    }
    return null;
  }

  render() {
    const { apiKeys, loading } = this.state;
    const activeKey = apiKeys.find(key => key.is_active);

    if (loading) {
      return <Loader type="dots" />;
    }

    return (
      <Card className="p-4">
        {this.renderAlerts()}
        
        <h4 className="mb-4">API Key</h4>
        
        {activeKey ? (
          <>
            <div className="api-key-display p-3 border rounded mb-4">
              <div className="mb-2">
                <strong>Your API Key:</strong>
                <Button 
                  color="link" 
                  size="sm"
                  className="ml-2"
                  onClick={() => this.copyToClipboard(activeKey.key)}
                >
                  <i className="fa fa-copy"></i> Copy
                </Button>
              </div>
              <code className="d-block p-2 bg-light">{activeKey.key}</code>
              <small className="text-muted mt-2 d-block mini-font">
                <i className="fa fa-info-circle mr-1"></i>
                Add this key in the x-api-key header to authenticate your API requests
              </small>
            </div>

            <div className="d-flex">
              <Button 
                color="primary" 
                onClick={this.generateApiKey}
                className="mr-2"
              >
                Regenerate Key
              </Button>
              
              <Button 
                color="danger" 
                onClick={() => this.deleteApiKey(activeKey.id)}
              >
                Delete Key
              </Button>
            </div>
          </>
        ) : (
          <div>
            <p>You don't have an API key yet.</p>
            <Button 
              color="primary" 
              onClick={this.generateApiKey}
            >
              Generate API Key
            </Button>
          </div>
        )}
      </Card>
    );
  }
}

export default ApiKeys;