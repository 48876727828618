import React, { Component } from 'react';
import { Button } from 'reactstrap';
import * as Icon from 'react-feather';
import InlineMultiLanguageInput from '../common/InlineMultiLanguageInput';
import { formOptionValueLanguageKeys } from './EditForm';
import { toMultiLanguageData } from '../../../vibe/helpers/language';
import { formOptionPlaceholderLanguageKeys } from './FormItemText';


class FormOptionText extends Component { 
    
    render() {
        const { formItem, option, } = this.props
        return <>
            <div className="input-group">
                <div className="d-flex align-items-center pr-2">
                    <Icon.AlignLeft size="20" color="#606469" />
                </div>

                <InlineMultiLanguageInput placeholder={'Title'} info={false} keys={formOptionValueLanguageKeys} data={toMultiLanguageData(option, formOptionValueLanguageKeys)} onChange={(data, key) => this.props.editFormOption(option, formItem, key, data)} />

                <div className="d-flex align-items-center ">
                    <Button color="link" className="pl-1" onClick={() => this.props.removeOption(formItem, option)}>
                        <Icon.X size="20" color="#606469" />
                    </Button>
                </div>
            </div>

            <div className="input-group">
                <div className="d-flex align-items-center pr-2" style={{visibility: 'hidden'}} >
                    <Icon.AlignLeft size="20" color="#606469" />
                </div>

                <InlineMultiLanguageInput placeholder={option.placeholder || "Write placeholder text here..."} info={false} keys={formOptionPlaceholderLanguageKeys} data={toMultiLanguageData(option, formOptionPlaceholderLanguageKeys)} onChange={(data, key) => this.props.editFormOption(option, formItem, key, data)} />
                
                <div className="d-flex align-items-center " style={{visibility: 'hidden'}}>
                    <Button color="link" className="pl-1" onClick={() => this.props.removeOption(formItem, option)}>
                        <Icon.X size="20" color="#606469" />
                    </Button>
                </div>
            </div>
            
        </>
    }

}

export default FormOptionText;