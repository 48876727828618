import React from 'react';
import { Link } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import { getAllOrganizationEvents, getOrganizationEvents } from '../../../../vibe/helpers/apiHelper';
import { getCurrentUser } from '../../../../vibe/helpers/apiHelper';
import { Loader } from '../../../../vibe';
import PaginationComp from '../../../../vibe/components/utilities/Pagination/Pagination';
import OrganizationEvent from './OrganizationEvent';
import { isSuperUser } from '../../../../vibe/helpers/permissionHelper';
import SearchInput from '../../../elements/form/SearchInput';

const pageSize = 10

class OrganizationEvents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            search: ''
        }
    }

    componentDidMount() {
        getCurrentUser((user) => {
            this.setState({user: user, search: '', currentPage: 1}, () => {
                this.getEvents(0)
            })
        })
    }

    getEvents(offset, search = '') {
        var where = { 
            status: {
                neq: 'archived'
            }
        }

        if (search) {
            where.title = {
                like: '%' + search + '%'
            }
        }

        if (isSuperUser(this.state.user)) {
            getAllOrganizationEvents(offset, where, ['updated_at DESC'], (organizationEvents, totalCount) => {
                this.setState({ organizationEvents: organizationEvents, totalCount: totalCount, loading: false, error: null })
            }, (error) => {
                this.setState({ totalCount: 0, currentPage: -1, error: error, loading: false })
            })
        } else {
            let organizationId = this.state.user.organization_id
            getOrganizationEvents(organizationId, offset, where, ['updated_at DESC'], (organizationEvents, totalCount) => {
                if (organizationEvents?.length > pageSize) {
                    organizationEvents = organizationEvents.slice(0, pageSize) // TODO remove this when DEV-5705 is fixed...
                }
                this.setState({ organizationId: organizationId, organizationEvents: organizationEvents, totalCount: totalCount, loading: false, error: null })
            }, (error) => {
                this.setState({ totalCount: 0, currentPage: -1, error: error, loading: false })
            })
        }
    }

    onClickPagination(e, page) {
        this.setState({ currentPage: page })
        var offset = (page - 1) * pageSize
        this.getEvents(offset, this.state.search)
    }

    renderEvents() {
        return this.state.organizationEvents.map((organizationEvent) => {
            return <OrganizationEvent user={this.state?.user} organizationEvent={organizationEvent} key={organizationEvent.id} />
        })
    }

    getLastPage() {
        return Math.ceil(this.state.totalCount / pageSize)
    }

    renderPagination() {
        return <PaginationComp currentPage={this.state.currentPage} lastPage={this.getLastPage()} onClickPagination={(e, page) => this.onClickPagination(e, page)} />
    }

    formatNumber(number) {
        if (number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }

    submitSearch(e) {
        e.preventDefault();
        this.setState({currentPage: 1}, () => {
            this.getEvents(0, this.state.search)
        })
    }

    onChangeSearch(e) {
        this.setState({search: e.target.value})
    }

    render() {
        if (this.state.loading) {
            return <Loader type="dots" />
        } else if (this.state.error) {
            return <div className='mx-3'>Error: {this.state.error}</div>
        } else {
            return <React.Fragment>
                <header className="app-header-page justify-content-end">
                    <div className="col col-md-3 mw-350">
                        <FormGroup className="mb-0 pt-1">
                            <Link className="btn btn-primary block" to={"/organization-events/new/" + this.state.organizationId}>
                                New Event
                            </Link>
                        </FormGroup>
                    </div>
                </header>

                <div className="row mb-3 align-items-center">
                    <div className="col">
                        <h1 className="h4 mb-0">Events</h1>
                        <small className="text-muted">{this.formatNumber(this.state.totalCount)} events</small>
                    </div>

                    <SearchInput onSubmitSearch={(e) => this.submitSearch(e)} onChangeSearch={(e) => this.onChangeSearch(e)} title={"Search by title..."} buttonText={'Search'} />

                </div>

                {this.renderEvents()}
                {this.state.totalCount > pageSize && this.renderPagination()}

            </React.Fragment>
        }
    }

}
export default OrganizationEvents
