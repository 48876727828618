import React, { Component } from 'react';
import { Button } from 'reactstrap';
import * as Icon from 'react-feather';
import { formOptionValueLanguageKeys } from './EditForm';
import { toMultiLanguageData } from '../../../vibe/helpers/language';
import InlineMultiLanguageInput from '../common/InlineMultiLanguageInput';


class FormItemMultipleChoice extends Component { 
    
    render() {
        const { formItem, option, } = this.props
        return <>
          <div className="input-group mb-3">
            <div className="d-flex align-items-center pr-2">
              <Icon.Circle size="20" color="#BDBDBD" /> 
            </div>

            <InlineMultiLanguageInput info={false} keys={formOptionValueLanguageKeys} data={toMultiLanguageData(option, formOptionValueLanguageKeys)} onChange={(data, key) => this.props.editFormOption(option, formItem, key, data)} />

            <div className="d-flex align-items-center ">
              <Button color="link" className="pl-1" onClick={() => this.props.removeOption(formItem, option)}>
                <Icon.X size="20" color="#606469" />
              </Button>
            </div>
          </div>
        </>
    }

}

export default FormItemMultipleChoice;