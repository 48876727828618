import { Component } from "react";
import { Row } from 'reactstrap';
import { isDelete } from "../../../elements/form/FormAction";
import { v4 as uuidv4 } from 'uuid';
import DropDownMultiSelect from "../../../elements/common/DropDownMultiSelect";
import { getOrganizationsInSameLevel } from "../../../../vibe/helpers/apiHelper";
import { parseErrorMessage } from "../../../../vibe/helpers/util";

const IGNORE_ORGANIZATIONS = [3215] // dont include UNDEFINED_NATION

class OrganizationEventCoHosts extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            organizationOptions: [],
            error: null
        }
    }
    
    componentDidMount() {

        const skipOrganizationOptions = IGNORE_ORGANIZATIONS
        if (this.props?.organizationId) {
            skipOrganizationOptions.push(this.props?.organizationId) // don't include their own organization
        }
        
        const includeOrganizationOptions = this?.props?.includeOrganizationOptionIds ?? []

        getOrganizationsInSameLevel((organizations) => {

            var organizationOptions = []
            for (let organization of organizations) {
                organizationOptions.push({ value: organization.id, label: organization.display_name})
            }

            if (includeOrganizationOptions.length > 0) {
                organizationOptions = organizationOptions.filter((option) => includeOrganizationOptions.includes(option.value))
            }

            if (skipOrganizationOptions.length > 0) {
                organizationOptions = organizationOptions.filter((option) => !skipOrganizationOptions.includes(option.value))
            }

            this.setState({organizationOptions: organizationOptions})   

        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.setState({ error: errorMessage })
        })
    }

    handleOrganizationSelect = (selectedOption, organizations) => {
        const selectedOrganization = {
            id: selectedOption.value,
            display_name: selectedOption.label
        };
    
        this.handleAddOrganization(selectedOrganization)
      };

      handleAddOrganization = (selectedOrganization) => {

        if (!selectedOrganization) {
            return
        }

        const isOrganizationAlreadyAdded = this.props.eventOrganizations.some((eventOrganization) => eventOrganization.organization_id === selectedOrganization.id);
        if (!isOrganizationAlreadyAdded) {
            const data = {
                key: uuidv4(),
                organization_id: selectedOrganization.id,
                organization: {
                    ...selectedOrganization
                }
                }
                this.props.onAddEventOrganization(data)
        } else {
            const organizationToDelete = this.props.eventOrganizations.find((eventOrganization) => eventOrganization.organization_id === selectedOrganization.id);
            if (organizationToDelete) {
                this.props.onDeleteEventOrganization(organizationToDelete)
            }
        }
    }

    renderOrganizations = (eventOrganizations) => {
        if (!eventOrganizations || eventOrganizations.length <= 0) {
            return null
        }

        return eventOrganizations.map((eventOrganization, index) => {
            if (!eventOrganization?.organization_id) {
                return null
            }

            if (isDelete(eventOrganization)) {
                return null
            }

            return <span key={index} className='mr-2 mb-2'>
                    <div
                        className={`d-flex badge-tag badge-pill badge-primary px-3 py-1`}>
                        {eventOrganization?.organization?.display_name}
                    </div>
                </span>
        })
    }

    onChangeOrganization(e, organizationOption) {
        e.preventDefault()
        this.handleOrganizationSelect(organizationOption, this.props.eventOrganizations)
    }

    organizationIsSelected(option) {
        const eventOrganizations = this.props.eventOrganizations
        if (!eventOrganizations || eventOrganizations.length <= 0) {
            return false
        }

        for (const eventOrganization of eventOrganizations) {
            if (eventOrganization.organization_id === option.value && !isDelete(eventOrganization)) {
                return true
            }
        }
        return false
    }

    render() {

        if (this.state.error) {
            return <div>{this.state.error}</div>
        }

        const { eventOrganizations } = this.props;
        return (
            <>
                <DropDownMultiSelect buttonDisabled={this.props?.disabled ?? false}  items={this.state.organizationOptions} onClickItem={(e, item) => this.onChangeOrganization(e, item)} itemIsSelected={(item) => this.organizationIsSelected(item)}  />
                <div className='col-12 mb-2'>
                    <Row className='justify-content-evenly'>
                        {eventOrganizations && this.renderOrganizations(eventOrganizations)}
                    </Row>
                </div>
            </>
        )
    }
}

export default OrganizationEventCoHosts;
