import React, { Component } from 'react';
import { Card, Button, Input, Collapse, ButtonGroup, ModalFooter } from 'reactstrap';
import { ChevronDown, ChevronUp, Info, Check, X, ArrowLeft, AlertCircle, FileText, Image as FileImage} from 'react-feather';
import {addMemberToOrganization, downloadMedia, getMediaById, patchMember, patchMemberCertificate, postVerifiedStudentInfo} from '../../../vibe/helpers/apiHelper';
import { shortedPersonalIdentificationNumber } from '../../../vibe/helpers/util';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class Member extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDetailsOpen:  false,
      isCertificateOpen: false,
      valid_from: '',
      valid_to: '',
      validationErrors: {},
      showRejectConfirmation: false,
      isChecked: false,
      certificateUrls: [],
      isLoadingCertificate: false,
      isPdf: false,
      certificateModalOpen: false,
      selectedCertificate: null,
      pace: 25,
    }
  }

  openCertificateModal = (certificateData) => {
    this.setState({
      selectedCertificate: certificateData,
      certificateModalOpen: true
    });
  }

  closeCertificateModal = () => {
    this.setState({
      selectedCertificate: null,
      certificateModalOpen: false
    });
  }

  renderCertificateList() {
    const { member } = this.props;
    const { certificateUrls } = this.state;
  
    // Collect all media from all certificates
    const allMedia = member?.member_certificate?.flatMap(cert => 
        cert.media?.map(media => ({
            ...media,
            certificateStatus: cert.status,  // Add certificate status to each media item
            certificateDate: cert.created_at,
            organizationName: cert.organization.display_name
        })) || []
    ) || [];
  
    if (allMedia.length === 0) {
      return (
        <div className="text-center text-muted p-3">
          <Info size={20} className="mb-2 text-secondary" />
          <p>No certificates available</p>
        </div>
      );
    }
  
    return (
      <div className="p-3">
        <div className="row no-gutters">
          {allMedia.map((media, index) => {
            const isPdf = media.mime_type === 'application/pdf';
            const url = certificateUrls[index];
            const isRejected = media.certificateStatus === 3;
            
            return (
              <div 
                key={media.id} 
                className="col-md-1 p-2"
                style={{ 
                  cursor: 'pointer',
                  opacity: isRejected ? 0.5 : 1,
                }}
              >
                <div 
                  className={`
                    border 
                    rounded 
                    transition-all 
                    p-3 
                    text-center 
                    ${isRejected 
                      ? 'bg-soft-danger border-danger' 
                      : 'hover-lift'
                    }
                  `}
                  style={{ 
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                  onClick={(e) => {
                    e.preventDefault();  // Prevent default behavior
                    e.stopPropagation();  // Add this to prevent event bubbling
                    this.openCertificateModal({
                      url,
                      isPdf,
                      fileName: media.file_name,
                      isRejected: isRejected,
                      createdAt: media.certificateDate,
                      orgName: media.organizationName
                    });
                  }}
                >
                  {isRejected && (
                    <div 
                      className="position-absolute top-0 right-0 bg-danger text-white px-2 py-1"
                      style={{ 
                        transform: 'rotate(45deg)', 
                        transformOrigin: 'top right',
                        position: 'absolute',
                        top: '-10px',
                        right: '-30px',
                        width: '120px',
                        textAlign: 'center',
                      }}
                    >
                      Rejected
                    </div>
                  )}
    
                  <div className="mb-2">
                    {isPdf ? (
                      <FileText 
                        size={48} 
                        className={`mx-auto ${isRejected ? 'text-muted' : 'text-danger'}`}
                        strokeWidth={1.5}
                      />
                    ) : (
                      <FileImage 
                        size={48} 
                        className={`mx-auto ${isRejected ? 'text-muted' : 'text-primary'}`}
                        strokeWidth={1.5}
                      />
                    )}
                  </div>
                  {/* <div className="text-truncate">
                    <small className={`${isRejected ? 'text-muted' : 'text-muted'}`}>
                      {media.file_name}
                    </small>
                  </div> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  
  formatDate(dateString){
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  renderCertificateModal() {
    const { selectedCertificate, certificateModalOpen } = this.state;
    const { member } = this.props;
    
    if (!selectedCertificate) return null;
    
    return (
      <Modal 
        isOpen={certificateModalOpen} 
        toggle={this.closeCertificateModal}
        size="xl"
        centered
        className="certificate-modal"
      >
        <ModalHeader 
          toggle={this.closeCertificateModal} 
          className="border-bottom-0"
        >
          <span className="text-muted">
            <small className="badge badge-primary ml-2">
              {member.first_name} {member.last_name}
            </small>
            <small className="badge badge-secondary ml-2">
              {member.personal_identification_number ?? member.email}
            </small>
            {selectedCertificate.isRejected && (
              <span className="badge badge-danger ml-2">Rejected</span>
            )}
       
            {selectedCertificate.orgName && (
              <small className="badge badge-success ml-2">
                {" "}{selectedCertificate.orgName}
              </small>
            )}
          </span>
        </ModalHeader>
        <ModalBody className={`p-0 ${selectedCertificate.isRejected ? 'bg-light' : ''}`}>
          <div className="text-center">
            {selectedCertificate.isPdf ? (
              <object
                data={selectedCertificate.url}
                type="application/pdf"
                width="100%"
                height="700px"
              >
                <div className="text-center p-4">
                  <a 
                    href={selectedCertificate.url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="btn btn-primary"
                  >
                    Open PDF
                  </a>
                </div>
              </object>
            ) : (
              <img 
                src={selectedCertificate.url}
                alt="Certificate"
                className="img-fluid"
                style={{ 
                  maxWidth: '100%', 
                  maxHeight: '700px', 
                  objectFit: 'contain',
                }}
              />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
            <span>
              {selectedCertificate.createdAt && (
                <small className="badge badge-info ml-2">
                  {" "}<b>Uploaded:</b> {this.formatDate(selectedCertificate.createdAt)}
                </small>
              )}
              <small className="badge badge-secondary ml-2">
                {selectedCertificate.fileName}
              </small>
            </span>
        </ModalFooter>
      </Modal>
    );
  }


  loadCertificateImage = () => {
    const { member } = this.props;
    
    // Collect all media from all certificates
    const allMedia = member?.member_certificate?.flatMap(cert => cert.media || []) || [];

    // Check if any media exists
    if (allMedia.length === 0) {
      console.error('No certificates available');
      this.setState({ 
          isLoadingCertificate: false, 
          certificateUrls: [],
          isPdf: false 
      });
      return;
    }

    // Limit to first 3 certificates
    const mediaItems = allMedia
    
    // Set loading state
    this.setState({ isLoadingCertificate: true });

    // Create download promises for each media item
    const downloadPromises = mediaItems.map((mediaItem) => {
        return new Promise((resolve) => {
            // First, get media details
            getMediaById(
                mediaItem.id,
                // Success callback for getMediaById
                (mediaDetails) => {
                    // Download the media file
                    downloadMedia(
                        mediaDetails.file_path,
                        // Success callback for downloadMedia
                        (blob) => {
                            // Create object URL
                            const objectURL = URL.createObjectURL(blob);
                            
                            resolve({
                                url: objectURL,
                                isPdf: mediaDetails.mime_type === 'application/pdf',
                                fileName: mediaItem.file_name
                            });
                        },
                        // Error callback for downloadMedia
                        (downloadError) => {
                            console.error(`Error downloading media ${mediaItem.id}:`, downloadError);
                            resolve(null);
                        }
                    );
                },
                // Error callback for getMediaById
                (mediaError) => {
                    console.error(`Error fetching media details for ${mediaItem.id}:`, mediaError);
                    resolve(null);
                }
            );
        });
    });

    // Process all download promises
    Promise.all(downloadPromises)
        .then((results) => {
            // Filter out null results (failed downloads)
            const validResults = results.filter(result => result !== null);

            // Update state with successful downloads
            this.setState({
                certificateUrls: validResults.map(result => result.url),
                isPdf: validResults.some(result => result.isPdf),
                isLoadingCertificate: false
            });
        })
        .catch((error) => {
            console.error('Error processing certificates:', error);
            this.setState({ 
                isLoadingCertificate: false,
                certificateUrls: [],
                isPdf: false
            });
        });
}

// Clean up object URLs when component unmounts
componentWillUnmount() {
    // Revoke any object URLs to prevent memory leaks
    this.state.certificateUrls.forEach(url => {
        URL.revokeObjectURL(url);
    });
}


  componentDidMount() {
    this.calculateValidDates();

    // Notify parent about default pace
    if (this.props.onUpdateMember) {
      this.props.onUpdateMember(this.props.member.id, { pace: 25 });
    }
  }


  calculateValidDates = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;
    
    let validFrom, validTo;
    
    // First half of the year: January 1st to June 30th
    if (currentMonth <= 6) {
      validFrom = `${currentYear}-01-01`;
      validTo = `${currentYear}-09-15`;
    } 
    // Second half of the year: July 1st to December 31st
    else {
      validFrom = `${currentYear}-07-01`;
      validTo = `${currentYear + 1}-02-10`;
    }
    
    this.setState({
      valid_from: validFrom,
      valid_to: validTo,
      validationErrors: { ...this.state.validationErrors, valid_from: null, valid_to: null }
    }, () => {
      // Update parent with date data
      if (this.props.onUpdateMember) {
        this.props.onUpdateMember(this.props.member.id, { 
          valid_from: validFrom,
          valid_to: validTo 
        });
      }
    });
  };

  
  validateFields = () => {
    // Only validate fields if we're accepting
    if (this.state.showRejectConfirmation) {
      return true; // Skip validation for rejection
    }

    const errors = {};
    
    if (!this.state.pace) {
      errors.pace = 'Please select a pace (25%, 50%, 75%, or 100%)';
    }

    if (!this.state.valid_from) {
      errors.valid_from = 'Start date is required';
    }

    if (!this.state.valid_to) {
      errors.valid_to = 'End date is required';
    }

    if (this.state.valid_from && this.state.valid_to) {
      if (new Date(this.state.valid_to) < new Date(this.state.valid_from)) {
        errors.valid_to = 'End date must be after start date';
      }
    }

    this.setState({ validationErrors: errors });
    return Object.keys(errors).length === 0;
  }

  renderValidationError = (field) => {
    const error = this.state.validationErrors[field];
    if (!error) return null;

    return (
      <div className="text-danger mt-1" style={{ fontSize: '0.8rem' }}>
        <AlertCircle size={12} className="mr-1" />
        {error}
      </div>
    );
  }
 
  acceptMember = (member) => {
    if (!this.validateFields()) {
      return;
    }

    if (member && !this.state.isAccepting) {
      this.setState({ isAccepting: true });

      const { pace, valid_from, valid_to, member_certificate, ...memberWithouCerttDates } = member;
      const updatedMember = {
        ...memberWithouCerttDates,
        status: member.status === 'A' ? 'A' : 'N',
      };
      
      patchMember(updatedMember, 
        () => {
          if (this.props.onMemberAccepted) {
            this.props.onMemberAccepted(member.id);
          }
          this.setState({ 
            isAccepting: false, 
            validationErrors: {}
          });

          if(member.country === 'SE'){
            var pinShort = shortedPersonalIdentificationNumber(this.props.member.personal_identification_number)

            let data = {
              personal_identification_number_short: pinShort,
              study_pace: this.state.pace.toString(),
              valid_from: new Date(this.state.valid_from),
              valid_to: new Date(this.state.valid_to)
            }
            postVerifiedStudentInfo(data, (result) => {
                console.log('Success', result)
            }, (error) => {
                console.log('Error', error)
            })
          }
          
          if (member.member_certificate && member.member_certificate.length > 0) {
            const lastCertificate = member.member_certificate[0]; 
            // First add member_membership only SE
            if(member.country === 'SE'){
              addMemberToOrganization(
                member.id,
                lastCertificate.organization_id,
                ()=>{
                  // Last Patch Certificate
                  patchMemberCertificate(
                    lastCertificate.id,
                    2, // Accepted status
                    () => {},
                    () => {},
                  );
                }
              )
            }else{
                 // Last Patch Certificate
                 patchMemberCertificate(
                  lastCertificate.id,
                  2, // Accepted status
                  () => {},
                  () => {},
                );
            }

          }
        }, 
        (error) => {
          console.error('Error accepting member:', error);
          this.setState({ 
            isAccepting: false, 
            validationErrors: { api: 'Failed to accept member. Please try again.' }
          });
        }
      );
     
    }
  }

  rejectMember = (member) => {
    if (member && !this.state.isRejecting) {
      this.setState({ isRejecting: true });

      const { pace, valid_from, valid_to, member_certificate,  ...memberWithouCerttDates } = member;
      const updatedMember = {
        ...memberWithouCerttDates,
        status: member.status === 'A' ? 'A' : 'C',
      };
      
      patchMember(updatedMember, 
        () => {
          if (this.props.onMemberAccepted) {
            this.props.onMemberAccepted(member.id);
          }
          this.setState({ 
            isRejecting: false, 
            showRejectConfirmation: false,
            validationErrors: {}
          });

          if (member.member_certificate && member.member_certificate.length > 0) {
            const certificateId = member.member_certificate[0].id;
            patchMemberCertificate(
              certificateId,
              3, // Rejected status
              () => {},
              () => {},
            );

          }
        }, 
        (error) => {
          console.error('Error rejecting member:', error);
          this.setState({ 
            isRejecting: false, 
            showRejectConfirmation: false,
            validationErrors: { api: 'Failed to reject member. Please try again.' }
          });
        }
      );
    }
  }


  toggleDetails = () => this.setState(prev => ({ isDetailsOpen: !prev.isDetailsOpen }));
  // toggleCertificate = () => this.setState(prev => ({ isCertificateOpen: !prev.isCertificateOpen }));
  toggleCertificate = () => {
    const newIsOpen = !this.state.isCertificateOpen;
    this.setState({ isCertificateOpen: newIsOpen });
    
    // Load certificate when opening if we have certificate data but no URLs yet
    if (newIsOpen && 
        this.props.member.member_certificate && 
        this.props.member.member_certificate.length > 0 && 
        this.props.member.member_certificate[0].media && 
        this.props.member.member_certificate[0].media.length > 0 && 
        this.state.certificateUrls.length === 0) {
            this.loadCertificateImage();
    }
}

  setPace = (pace) => {
    this.setState({
      pace: pace,
      isChecked: true,  
      validationErrors: { ...this.state.validationErrors, pace: null }
    }, () => {
      // Update parent with pace data
      if (this.props.onUpdateMember) {
        this.props.onUpdateMember(this.props.member.id, { pace });
      }
    });
  }

  handleDateChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      validationErrors: { ...this.state.validationErrors, [name]: null }
    }, () => {
      // Update parent with date data
      if (this.props.onUpdateMember) {
        this.props.onUpdateMember(this.props.member.id, { [name]: value });
      }
    });
  }

  showRejectConfirmation = () => {
    this.setState({ showRejectConfirmation: true });
  }

  cancelRejectConfirmation = () => {
    this.setState({ 
      showRejectConfirmation: false,
      validationErrors: {}
    });
  }

  renderActionButtons() {
    const { member } = this.props;
    const { isAccepting, isRejecting, showRejectConfirmation } = this.state;
  
    if (showRejectConfirmation) {
      return (
        <div className='text-small w-sm-100 align-items-center d-flex'>
          <div>
            <Button 
              color="link" 
              size="sm" 
              className="mr-2 text-muted w-full"
              onClick={this.cancelRejectConfirmation}
              disabled={isRejecting}
            >
              <ArrowLeft size={14} className="mr-1" />
              Back
            </Button>
            <Button 
              color="danger" 
              size="sm" 
              className="mr-2 my-0 mx-0 w-full" 
              onClick={() => this.rejectMember(member)}
              disabled={isRejecting}
            >
              {isRejecting ? (
                <>
                  <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                </>
              ) : (
                <>
                  <X size={14} className="mr-1" />
                  Confirm
                </>
              )}
            </Button>
          </div>
        </div>
      );
    }
  
    return (
      <div className='text-small w-sm-100 align-items-center d-flex'>
        <div>
          <Button 
              color="danger" 
              size="sm" 
              className="mx-0 my-0 mb-1 w-full mr-2"
              onClick={this.showRejectConfirmation}
            >
              <X size={14} className="mr-1" />
              Reject
            </Button>
          <Button 
            color="success" 
            size="sm" 
            className="my-0 mx-0 w-full" 
            onClick={() => this.acceptMember(member)}  // Directly call acceptMember
            disabled={isAccepting}
          >
            {isAccepting ? (
              <>
                <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
              </>
            ) : (
              <>
                <Check size={14} className="mr-1" />
                Accept
              </>
            )}
          </Button>
        </div>
      </div>
    );
  }

  handleCheckboxChange = (e) => {
    const { member, onSelectMember } = this.props;
    this.setState({ isChecked: e.target.checked });
    if (onSelectMember) {
      onSelectMember(member.id, e.target.checked);
    }
  };

  
  render() {
    const { member, validationErrors: bulkValidationErrors = {} } = this.props;
    const { isDetailsOpen, isCertificateOpen, pace, validationErrors: singleValidationErrors, isLoadingCertificate } = this.state;
  
    // Use bulk validation errors if they exist, otherwise use local state
    const errors = Object.keys(bulkValidationErrors).length > 0 
    ? bulkValidationErrors 
    : singleValidationErrors;
  
    const sourceColor = member.source === 'web' ? {bg:'#f3e8ff', color: '#6b21a8'} 
                      : member.source === 'app-iOS' ? {bg:'#dbeafe', color: '#1e40af'} 
                      : member.source === 'app-android' ? {bg:'#dcfce7', color: '#166534'}
                      : {bg:'#ebf6f7', color: '#4bafb7'};
  
    // Load certificates if they're not loaded yet
    if (member.member_certificate && 
        member.member_certificate.length > 0 && 
        member.member_certificate[0].media && 
        member.member_certificate[0].media.length > 0 && 
        this.state.certificateUrls.length === 0 &&
        !isLoadingCertificate) {
      this.loadCertificateImage();
    }
  
    // Get all media from certificates
    const allMedia = member?.member_certificate?.flatMap(cert => 
      cert.media?.map(media => ({
        ...media,
        certificateStatus: cert.status,
        certificateDate: cert.created_at,
        organizationName: cert.organization.display_name
      })) || []
    ) || [];
  
    return (
      <Card className={`mb-3 pendingMember fade-card ${member.isRemoving ? 'fade-out' : ''}`} style={{ transition: 'all 0.5s ease-out' }}>
        <label htmlFor={`check-${member.id}`}>
        <div className="card-body">
          {/* Main Row */}
          <div className="d-flex justify-content-between align-items-center">
            {/* Left Side */}
            <div className="d-flex align-items-center">
              <div className="custom-control custom-checkbox">
              <input 
                  type="checkbox" 
                  className="custom-control-input" 
                  id={`check-${member.id}`}
                  checked={this.props.isSelected}
                  onChange={this.handleCheckboxChange}
                />
                <label className="custom-control-label" htmlFor={`check-${member.id}`}></label>
              </div>
              
              <div>
                <div className="d-flex align-items-center">
                  <h6 className="mb-0 mr-2">{member.first_name} {member.last_name}</h6>
                  <span className={member.country + ' countryBadge'}>
                    {member.country}
                  </span>
                </div>
                <small className="text-muted">
                  {member.personal_identification_number ?? member.email} • {member.member_certificate[0].organization.display_name}
                </small>
  
                <div className='mt-1 d-flex align-items-center'>
                  <Button color="link" className="p-0 mr-2" onClick={this.toggleDetails}>
                    <Info size={16} />
                  </Button>
                  
                  {/* Display certificates inline instead of in a collapsible section */}
                  {isLoadingCertificate ? (
                    <div className="mini-font badge bg-opacity-10" style={{backgroundColor: '#4dff0730', color: '#096000', fontWeight: '500'}}>
                      <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> Loading...
                    </div>
                  ) : member.member_certificate && allMedia.length > 0 ? (
                    <div className="d-flex">
                      {allMedia.map((media, index) => {
                        const isPdf = media.mime_type === 'application/pdf';
                        const url = this.state.certificateUrls[index];
                        const isRejected = media.certificateStatus === 3;
                        
                        // Skip if URL is not loaded yet
                        if (!url) return null;
                        
                        return (
                          <div 
                            key={media.id}
                            className="mr-2 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();  // Prevent default behavior
                              e.stopPropagation();  // Add this to prevent event bubbling
                              this.openCertificateModal({
                                url,
                                isPdf,
                                fileName: media.file_name,
                                isRejected: isRejected,
                                createdAt: media.certificateDate,
                                orgName: media.organizationName
                              });
                            }}
                          >
                            {isPdf ? (
                              <FileText size={24} className={isRejected ? "text-danger fade-50" : "text-primary"} />
                            ) : (
                              <FileImage size={24} className={isRejected ? "text-danger fade-50" : "text-primary"} />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <span className="mini-font badge bg-opacity-10 cert-badge" style={{backgroundColor: '#ffc10730', color: '#854d0e', fontWeight: '500'}}>
                      Missing Certificate
                    </span>
                  )}
                </div>
              </div>
            </div>
  
            {/* Right Side - unchanged */}
            <div className="d-flex align-items-center justify-content-end" style={{maxHeight: '40px'}}>
              <div className=''>
                <label className='mini-xs-font'>Study Pace</label>
                <div className='mr-2' style={{
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: errors.pace ? '#dc3545' : '#dee2e6',
                    borderRadius: '8px', 
                    boxShadow: 'none', 
                    padding: '1px 2px'
                  }}>
                  <ButtonGroup> 
                  {[25, 50, 75, 100].map((value) => (
                    <Button 
                      key={value}
                      color={pace === value ? "primary" : ""}
                      className="px-1"
                      style={{boxShadow: 'none', borderRadius: '8px'}}
                      onClick={() => this.setPace(value)}
                    >
                      {value}%
                    </Button>
                  ))}
                  </ButtonGroup>
                </div>
              </div>
  
              <div className="text-small w-sm-100 align-items-center d-flex">
                <div>
                  <label className='mini-xs-font'>Valid from</label>
                  <Input
                    type="date"
                    bsSize="sm"
                    className="mr-2"
                    style={{
                      width: '115px',
                      borderColor: errors.valid_from ? '#dc3545' : '#dee2e6'
                    }}
                    name="valid_from"
                    title="Valid from"
                    value={this.state.valid_from} 
                    onChange={this.handleDateChange}
                  />
                </div>
              </div>
  
            <div className='text-small w-sm-100 align-items-center d-flex mr-2'>
              <div>
                <label className='mini-xs-font'>Valid to</label>
                <Input
                  type="date"
                  bsSize="sm"
                  className="mr-2"
                  name="valid_to"
                  style={{
                    width: '115px',
                    borderColor: errors.valid_to ? '#dc3545' : '#dee2e6'  
                  }}
                  title="Valid to"
                  value={this.state.valid_to}
                  onChange={this.handleDateChange}
                />
               </div>
              </div>
  
              {this.renderActionButtons()}
  
            </div>
          </div>
        </div>
        </label>
  
          {/* Display API Error if any */}
          {errors.api && (
            <div className="text-danger mt-2">
              <AlertCircle size={14} className="mr-1" />
              {errors.api}
            </div>
          )}
  
          {/* Details Collapse - unchanged */}
          <Collapse isOpen={isDetailsOpen}>
            <div className="p-3 border-top bg-light" style={{borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px'}}>
              <div className="row mini-md-font" style={{marginLeft: "30px"}}>
                <div className="col-md-6">
                  <span className="text-muted">Address: </span>
                  {member.street_address ?? '-'}, {member.zip_code ?? '-'}
                </div>
                <div className="col-md-6">
                  <span className="text-muted">Email: </span>
                  {member.email ?? '-'}
                </div>
                <div className="col-md-6">
                  <span className="text-muted">City: </span>
                  {member.city ?? '-'}
                </div>
                <div className="col-md-6">
                  <span className="text-muted">Source: </span>
                  <span className={`badge badge`} style={{backgroundColor: sourceColor.bg, color: sourceColor.color, fontWeight: '500'}}>
                    {member.source ?? "Mystery"}
                  </span>
                </div>
              </div>
            </div>
          </Collapse>
  
          {this.renderCertificateModal()}
        </Card>
      );
    }
}

export default Member;