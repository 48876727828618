import React, { Component } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { KEY_PRIMARY, KEY_SECONDARY } from '../../../vibe/helpers/language';
import SimpleHelpPopover from '../../elements/common/SimpleHelpPopover';

class FieldLanguageToggle extends Component {
    
    render() {

        const {activeLanguage, primary, secondary, info, classNames} = this.props
        const showInfo = info ?? true

        const buttonGroupClassNames = classNames ?? ""

        return (
            <ButtonGroup className={buttonGroupClassNames}>

                {showInfo && <SimpleHelpPopover color="info" placement="left" title="Language">
                    <b>Sv (required)</b>
                    <p>Text displayed for users who have Swedish language selected in the app. This is the default, shown to all users if [En] is left empty.</p>
                    <b>En (optional)</b>
                    <p>Text displayed for users who have English language selected in the app.</p>
                </SimpleHelpPopover>}

                <Button
                    color={activeLanguage === KEY_PRIMARY ? 'primary' : 'secondary'}
                    onClick={(e) => this.props.toggle(KEY_PRIMARY)}>
                    {primary ? primary : 'Swedish'}
                </Button>

                <Button
                    color={activeLanguage === KEY_SECONDARY ? 'primary' : 'secondary'}
                    onClick={(e) => this.props.toggle(KEY_SECONDARY)}>
                    {secondary ? secondary : 'English'}
                </Button>

            </ButtonGroup>
        )

    }
}

export default FieldLanguageToggle;