import React, { Component } from 'react';
import { Button } from 'reactstrap';
import * as Icon from 'react-feather';
import { toMultiLanguageData } from '../../../vibe/helpers/language';
import InlineMultiLanguageInput from '../common/InlineMultiLanguageInput';

export const formOptionPlaceholderLanguageKeys = {
  primary: 'placeholder', 
  secondary: 'placeholder_en'
}

class FormItemText extends Component { 

    render() {
        const { formItem } = this.props
        const option = formItem?.form_options?.[0]

        return <>
        <div className='mx-4 col-6'>
            <div>
              <div className="input-group mb-3">
                <div className="d-flex align-items-center pr-2">
                  <Icon.AlignLeft size="20" color="#606469" />
                </div>

                <InlineMultiLanguageInput placeholder={option.placeholder || "Write placeholder text here..."} info={false} keys={formOptionPlaceholderLanguageKeys} data={toMultiLanguageData(option, formOptionPlaceholderLanguageKeys)} onChange={(data, key) => this.props.editFormOption(option, formItem, key, data)} />

                <div style={{visibility: 'hidden'}}> {/* maintain consistent spacing */}
                  <Button color="link" className="pl-1">
                      <Icon.X size="20" color="#606469" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
    }

}

export default FormItemText;