import React, { Component } from 'react';
import MultiLanguageWrapper from '../../pages/common/MultiLanguageWrapper';
import SmallTextAreaValued from './SmallTextAreaValued';

class MultiLanguageSmallTextArea extends Component {

    render() {
      return (
        <MultiLanguageWrapper
          buttonGroupClassNames={this.props.buttonGroupClassNames ?? 'mb-1'}
          label={this.props.label}
          data={this.props.data}
          keys={this.props.keys}
          onChange={this.props.onChange}>
          {({ content, onChange }) => (
            <SmallTextAreaValued value={content} onChange={(e) => onChange(e.target.value)} />
          )}
        </MultiLanguageWrapper>
      );
    }
  
  }
  
  export default MultiLanguageSmallTextArea;
  