import React from 'react';
import { Input } from 'reactstrap';
import InlineMultiLanguageWrapper from './InlineMultiLanguageWrapper';

class InlineMultiLanguageInput extends React.Component { 

    render() {
        return (
          <InlineMultiLanguageWrapper
            placeholder={this.props.placeholder}
            info={this.props.info}
            buttonGroupClassNames={this.props.buttonGroupClassNames}
            data={this.props.data}
            keys={this.props.keys}
            onChange={this.props.onChange}
            isInvalid={this.props.isInvalid}>
            {({ content, onChange, isInvalid, placeholder }) => (
              <Input
                placeholder={placeholder}
                invalid={isInvalid}
                type="text"
                value={content}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          </InlineMultiLanguageWrapper>
        );
    }

}

export default InlineMultiLanguageInput