import React from 'react';
import { Input } from 'reactstrap';
import MultiLanguageWrapper from './MultiLanguageWrapper';

class MultiLanguageInput extends React.Component { 

    render() {
        return (
          <MultiLanguageWrapper
            placeholder={this.props.placeholder}
            buttonGroupClassNames={this.props.buttonGroupClassNames ?? 'mb-1'}
            label={this.props.label}
            showLabel={this.props.showLabel}
            data={this.props.data}
            keys={this.props.keys}
            onChange={this.props.onChange}
            isInvalid={this.props.isInvalid}>
            {({ content, onChange, isInvalid, placeholder }) => (
              <Input
                placeholder={placeholder}
                invalid={isInvalid} 
                type="text"
                value={content}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          </MultiLanguageWrapper>
        );
    }

}

export default MultiLanguageInput